<template>
  <v-container class="fill-height secondary un-bg" fluid>
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card flat class="elevation-3">
          <v-toolbar color="primary" dark flat>
            <v-img max-height="40" max-width="40" src="/logo.png" />
            <v-toolbar-title
              class="text-uppercase font-weight-bold subtitle-2"
              style="padding: 0 3px;"
              >UNDSS SRS</v-toolbar-title
            >
            <v-spacer />
          </v-toolbar>
          <v-card-text>
            <v-form @submit.prevent>
              <v-row>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.password"
                    :rules="rules.password"
                    :label="$t('user.newPassword')"
                    name="password"
                    append-icon="mdi-lock"
                    type="password"
                  />
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="model.confirmPassword"
                    :rules="rules.confirmPassword"
                    :label="$t('user.confirmPassword')"
                    name="confirm"
                    append-icon="mdi-lock-check"
                    type="password"
                  />
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="primary" tile depressed @click="navigateToLogin">
              {{ $t("login.login") }}
            </v-btn>
            <v-spacer />
            <v-btn color="primary" tile depressed @click="tryResetPassword">
              {{ $t("general.reset") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-row no-gutters align="center" justify="center">
      <copyright :size="64" />
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from "vuex";
import Copyright from "@/common/components/Copyright";

export default {
  name: "ResetPassword",
  components: { Copyright },
  data() {
    return {
      redirect: "/",
      model: { password: "", confirmPassword: "", resetCode: "" },
      rules: {
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (!!v && v.length >= 8) || "Password must be at least 8 characters",
          (v) => {
            const re = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
            return (
              re.test(String(v).toLowerCase()) ||
              "Minimum eight characters, at least one letter, one number and one special character"
            );
          },
        ],
        confirmPassword: [
          (v) => !!v || "Password is required",
          (v) =>
            v === this.model.password || "Password and confirmation must match",
        ],
      },
    };
  },
  methods: {
    ...mapActions("auth", ["resetPassword"]),

    tryResetPassword() {
      this.model.resetCode = this.$route.query.code;
      this.resetPassword(this.model).then(() =>
        this.$router.push({
          name: "dashboards",
        })
      );
    },
    navigateToLogin() {
      this.$router.push({
        name: "login",
      });
    },
  },
};
</script>
