<template>
  <div class="copyright d-flex align-center">
    <v-btn href="https://www.unocha.org/yemen" x-small text tile class="pt-0 pb-0">
      <span class="white--text" style="font-size: 8pt;"
        >Copyright &copy; 2024</span
      >
      <pre> </pre>
      <v-img
        src="/images/un-logo-white.png"
        :height="size"
        :max-width="size"
        contain
      />
    </v-btn>
    <pre></pre>
    <v-btn href="https://immap.org/" x-small text tile class="pt-0 pb-0">
      <span class="white--text" style="font-size: 8pt;">DEVELOPED BY</span>
      <pre> </pre>
      <v-img
        src="/images/immap-logo-white.png"
        :height="size"
        :max-width="size"
        contain
      />
    </v-btn>
    <slot>
    </slot>
  </div>
</template>

<script>
export default {
  name: "copyright",
  props: {
    size: {
      type: Number,
      default() {
        return 20;
      },
    },
  },
};
</script>

<style scoped>
.copyright * {
  cursor: pointer;
}
.copyright pre {
  cursor: default;
}
.copyright .v-btn:before {
  line-height: 10px;
}
</style>
